<template>
  <div class="animated fadeIn">
  <b-row>
    <b-col cols="12" lg="12">
      <b-card class="w-100" no-header>
        <template slot="header">
          <span class="mt-2">GDPR Audit</span>
          <b-button @click="goBack" class="btn-sm pull-right">Back</b-button>
        </template>

        <b-card no-body id="data">

          <table class="table" v-if="data">
            <tbody>
                <tr v-for="(v, k, index) in data" :key="index" v-if="k !== 'id' && k !== 'all_groups' ">
                    <th>{{ k|capitalize }}</th>
                    <td>{{ v }}</td>
                </tr>
            </tbody>
          </table>

        </b-card>

        <template slot="footer">
          <b-button @click="goBack" class="float-left">Back</b-button>
        </template>

      </b-card>
    </b-col>
  </b-row>

  </div>

</template>

<script>
  import axios from '../../../shared/axios/auth-no-loader'
  import Id from "bootstrap-vue/src/mixins/id";
  import base64url from "base64url";

  export default {
    name: 'GDPRAudit',
    components: {Id},
    props: {
      caption: {
        type: String,
        default: 'GDPR Audit'
      },
    },
    data: () => {
      return {
        data: null,
        pdf: null,
        pdf_link: null,
        get_document: false
      }
    },
    methods: {
      goBack() {
        this.$router.go(-1)
      },
      getAudit() {
        let url = '';

        if (localStorage.getItem('selected_product')) {
          url = '/gdpr-audits/' + this.$route.params.id + '/?product=' + localStorage.getItem('selected_product');
        } else {
          url = '/gdpr-audits/' + this.$route.params.id + '/';
        }
        axios.get(url).then(
          response => {
            this.data = response.data
          }
        ).catch(error => {
            // Unauthorised
            if (error.response.status === 401) {
              this.$store.dispatch('logout');
            }
            console.log('gdpr audit error');
            console.log(error.response)
          })
        return this.data
      }
    },
    computed: {
      hasFullAccess() {

        let access = ['full_admin',];

        let allowed = false;

        access.forEach(item => {
          if (this.$store.getters.getUserPermissions.includes(item)) {
            allowed = true
          }
        })

        return allowed

      },
    },
    mounted() {
      this.getAudit()
    },
    watch: {
      $route(to, from) {
        // react to route changes...
        this.$router.replace(to.fullPath).catch(error => {
          console.log(error)
        }).then(
          this.getAudit()
        );

      }
    }
  }
</script>

<style>

  #data .card-header {
    padding-top: 1.5rem;
  }
</style>
